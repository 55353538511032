button {
	user-select: none;
}

.controls {
	position: fixed;
	bottom: 4rem;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.record-btn {
	width: 3.7rem;
	height: 3.7rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 3px solid white;
}

.record-btn .dot {
	width: 2.5rem;
	height: 2.5rem;
	margin-left: 1px;
	border-radius: 50%;
	background-color: red;
}