@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: urbanist;
  src: url(./assets/fonts/Urbanist/Urbanist-VariableFont_wght.ttf);
}

#root {
  height: 100dvh;
  font-family: urbanist;
}

.h-dscreen {
  height: 100vh;
  height: 100dvh;
}

.w-dscreen {
  width: 100vw;
  width: 100dvw;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* stylized scrollbar */
.dense-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.dense-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.dense-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.dense-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dense-scrollbar::-webkit-scrollbar-corner {
  background: #f1f1f1;
}
