.header {
	background: url(../../assets/images/welcome.jpeg), lightgray 50% / cover no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	padding-top: 3rem;
	padding-bottom: 1.5rem;
	min-height: 300px;
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% + 1px);
	background: linear-gradient(180deg, #FFFFFFDD 0%, #FFF 100%);
}