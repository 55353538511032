.home {
	background-color: #D9D9D9;
	background-image: url('../../assets/images/welcome.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(25, 23, 24, 0.00) 11.97%, #191718 65.82%, rgba(240, 10, 51, 0.80) 96.1%);
}

.content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}