.container {
	position: fixed;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	height: 100dvh;
	width: 100dvw;
	z-index: 30;
	padding-top: 1rem;
	background-color: theme('colors.white');
}

.list {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: auto;
	align-items: center;
	justify-content: flex-start;
	padding: 1.6rem;
	padding-top: 0;
	max-width: 600px;
}

.list::-webkit-scrollbar {
	width: 0.5rem;
}
.list::-webkit-scrollbar-track {
	background-color: theme('colors.gray.200');
	border-radius: 0.4rem;
}
.list::-webkit-scrollbar-thumb {
	background-color: theme('colors.gray.400');
	border-radius: 0.4rem;
}
.list::-webkit-scrollbar-thumb:hover {
	background-color: theme('colors.gray.500');
}