.alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: theme('colors.gray.900');
	color: white;
	text-wrap: pretty;
	padding: 1rem;
	border-radius: 3rem;
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	gap: .5rem;
	white-space: nowrap;
}

.alert.error {
	background: theme('colors.feedback.error');
	color: theme('colors.white');
}
.alert.success {
	background: theme('colors.feedback.success');
	color: theme('colors.white');
}
.alert.warning {
	background: theme('colors.feedback.warning');
	color: theme('colors.white');
}
.alert.info {
	background: theme('colors.feedback.info');
	color: theme('colors.white');
}