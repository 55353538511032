.top-right-actions {
	position: fixed;
	top: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: .5rem;
	z-index: 10;
}

.stream-ended {
	position: fixed;
	top: 0; left: 0;
	height: 100dvh;
	width: 100%;
	display: flex;
	gap: 2rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: rgba(59, 57, 57, 0.70);
	backdrop-filter: blur(25px);
}