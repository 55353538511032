.item {
	padding: .25rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5rem;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
	flex: 1;
}
.icon {
	color: theme('colors.primary.DEFAULT') !important;
}

.item-active {
	background: theme('colors.primary.DEFAULT');
	color: theme('colors.white');
}
.item-active .icon {
	color: theme('colors.white') !important;
}

.item-disabled {
	cursor: not-allowed !important;
	background: theme('colors.gray.200');
	color: theme('colors.gray.700');
}
.item-disabled .icon {
	color: theme('colors.gray.700') !important;
}