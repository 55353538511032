.counter-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	color: white;
	background-color: #D9D9D9;
	background-image: url('../../assets/images/welcome.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	overflow: auto;
}

.gradient {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(25px);
	background: rgba(59, 57, 57, 0.70);
}