.container {
	display: flex;
	align-items: center;
	user-select: none;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: .3rem;
		margin: 0 .5rem;
	}
}