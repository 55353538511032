.load {
	width: 400px;
	height: 400px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&::before, &::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0;
		height: 0;
		border-radius: 50%;
		box-sizing: border-box;
		animation: animate 3s linear infinite;
	}
	&::after {
		animation-delay: 1.5s;
	}
}

@keyframes animate {
	0% {
		width: 50%;
		height: 50%;
		border: 4px solid #cccccc22;
		opacity: 0;
		box-shadow: 0 0 10px #cccccc22;
	}
	30% {
		opacity: 1;
	}
	70% {
		opacity: .5;
	}
	100% {
		width: 100%;
		height: 100%;
		border: 4px solid #cccccc22;
		box-shadow: 0 0 100px #cccccc22;
		opacity: 0;
	}
}